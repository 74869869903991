<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->

		<div class="row">
			<div class="col-md-12">

				<div class="card mt-3 shadow">
					<div class="card-header">
						<div class="float-left mt-2">
							<h5>创建制度</h5>
						</div>
						
						<div class="float-right">
							<button @click="back" type="type" class="btn btn-outline-dark">
								<i class="icon icon-chevron-circle-left"></i>返回
							</button>
						</div>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">
							
							<!-- <div class="form-group row">
								<label class="col-md-3 form-label">费用类型</label>
								<div class="col-md-9">
									<select class="form-control" v-model="institution.expense_type">
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in expensetypelist">{{item.name}}</option>
									</select>
								</div>
							</div> -->

							<div class="form-row">
								<!-- <div class="col-md-4 mb-3">
									<label for="">因公场景*</label>
									<select class="form-control" v-model="institution.scene_type">
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in scenetypelist">{{item.name}}</option>
									</select>
								</div> -->
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">归属企业</label>
									<select class="form-control" v-model="institution.dept_id">
										<option value="">选择企业</option>
										<template v-for="(item,index) in departmentlist">
										<option v-bind:key="index"
											:value="item.id"  v-if="item.enabled==1">
											{{item.department_name}}</option>
										</template>
									</select>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">福利分类</label>
									<select class="form-control" v-model="institution.category">
										<option value="">选择分类</option>
										<template v-for="(item,index) in categorylist">
										<option v-bind:key="index"
											:value="item.value">
											{{item.name}}</option>
										</template>
									</select>
								</div>
								
								
								<!-- <div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">费用类型</label>
									<select @change="onChangeExpenseType" class="form-control" v-model="institution.expense_type">
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in expensetypelist">{{item.name}}</option>
									</select>
								</div> -->
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">制度类型</label>
									<select @change="onChangeSubExpenseType" class="form-control" v-model="institution.expense_type_sub_category">
										<option v-bind:key="index" :value="item.value"
											v-for="(item,index) in subexpensetypelist">{{item.name}}</option>
									</select>
								</div>
								
							</div>

							<div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">制度名称</label>
									<input v-model="institution.institution_name" type="text" class="form-control"
										placeholder="制度名称">
									<div class="text-muted s-12">
										20个字以内，会出现在支付时企业代扣选择的"虚拟卡"名称
									</div>
								</div>
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">金额(元)</label>
									<input v-model="institution.quota_total" type="number" class="form-control" 
									placeholder="请输入制度福利金额">
									<div class="text-muted s-12">
										
									</div>
								</div>
								
								<div class="col-md-4 mb-3" v-if="institution.expense_type_sub_category == 'METRO' ">
									<label for="" class="font-weight-bold">卡类型</label>
									<br>
									
									<input  v-model="institution.card_list" type="text" class="form-control" >
									   
									<small class="form-text text-muted">
										多个卡类型之间用英文逗号隔开
									</small>
								</div>
								
								<!-- <div class="col-md-4 mb-3" 
								v-if="institution.expense_type_sub_category == 'TAKE_AWAY'">
									<label for="" class="font-weight-bold">外卖品类</label>
									<select  class="form-control" v-model="take_away_category_index">
										<option v-bind:key="index" :value="index"
											v-for="(item,index) in take_away_category_list">{{item.name}}</option>
									</select>
								</div> -->
							</div>
							
							<div class="form-row">
								
								
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">有效期开始日期</label>
									<div>
									<date-picker v-model="institution.effective_start_date" format="YYYY-MM-DD" value-type="format"
										input-class="form-control" :editable="false" :clearable="false">
									</date-picker>
									</div>
									<div class="text-muted s-12">
										最早是当日
									</div>
								</div>
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">有效期结束日期</label>
									<div>
									<template v-if="is_longtime==0">
										<date-picker v-model="institution.effective_end_date" format="YYYY-MM-DD" value-type="format"
											input-class="form-control" :editable="false" :clearable="false">
										</date-picker>
									</template>
									<span class="ml-2">
										<input type="checkbox" @change="onChangeEndDate" v-model="is_longtime" value="1" id="chk_end_date" name="chk_end_date">
										<label for="chk_end_date">长期</label>
									</span>	
										
									</div>
									<div class="s-12">
										结束时间不早于起始时间
									</div>
								</div>
							</div>
							
							<div class="form-row">
								<div class="col-md-4 mb-3">
									<label for="" class="font-weight-bold">备注</label>
									<br>
									
									<input  v-model="institution.remark" type="text" class="form-control" >
									   
									<small class="form-text text-muted">
										<!-- 输入一些便于记忆的备注 -->
									</small>
								</div>
							</div>
							
						</div>
					</div>

				</div>


				<!-- //// -->
				<div class="card my-3 shadow no-b r-0"
				v-if="institution.expense_type_sub_category == 'DEFAULT' || institution.expense_type_sub_category == 'REACH_SHOP' || institution.expense_type_sub_category == 'TAKE_AWAY'"
				>
					<div class="card-header white">
						<h6>
							使用条件
						</h6>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">
							<div class="form-row">
								<div class="col-md-4">
									<label for=""  class="font-weight-bold">
										可用商户
									</label>
									
									<div class="form-group">
										<!-- 餐饮不限，相当到店时的MCC为-1，外卖时为全量餐饮美食MEAL -->
										<div>
											<input @click="onChangeMchScope(0)" v-model="mch_scope" value="0" checked type="radio" name="ms" id="ms1" class="with-gap">
											<label for="">不限</label>
										</div>
										
										
										<!-- v-if="institution.expense_type_sub_category && institution.expense_type_sub_category != 'DEFAULT'" -->
										<div>
											<input @click="onChangeMchScope(1)" v-model="mch_scope" value="1" type="radio" name="ms" id="ms3" class="with-gap">
											<label for="" class="m-l-20">指定商户</label>
											<span class="text-muted ml-2" v-if="mchList.length>0">选择{{mchList.length}}个商户</span>
										</div>
										
										<div
										 v-if="institution.expense_type_sub_category == 'TAKE_AWAY'">
											<input  @click="onChangeMchScope(2)" v-model="mch_scope" value="2" type="radio" name="ms" id="ms2" class="with-gap">
											<label for="" class="m-l-20">指定商户类型(仅适用于 餐饮-外卖)</label>
											
											<span class="text-muted ml-2" v-if="take_away_category_obj.name!=undefined">
											{{take_away_category_obj.name}}
											</span>
											
											
										</div>
										
										<div
										 v-if="institution.expense_type_sub_category == 'REACH_SHOP'">
											<input  @click="onChangeMchScope(3)" v-model="mch_scope" value="3" type="radio" name="ms" id="ms3" class="with-gap">
											<label for="" class="m-l-20">指定商户类型(仅适用于 餐饮-到店)</label>
											
											<span class="text-muted ml-2">
											</span>
										</div>
									</div>
									
								</div>
								<!-- <div class="col-md-4">
									<label for=""  class="font-weight-bold">
										超额支付模式
										<br>
										<span class="s-12">当笔消费金额大于规则可用余额时</span>
									</label>
									
									<div class="form-group  mt-2">
										<input checked type="radio" name="pp" id="male" class="with-gap">
										<label for="male">超额部分由个人支付(即组合付款)</label>
										<br>
										<input type="radio" name="pp" id="female" class="with-gap">
										<label for="female" class="m-l-20">整单全部由个人支付</label>
									</div>
									
								</div> -->
								<!-- <div class="col-md-4">
									<label for="" class="font-weight-bold">
										是否可转赠额度
									</label>
									<div class="form-group mt-2">
										<input v-model="institution.share_mode" value="0" type="radio" name="s" id="sm0" class="with-gap">
										<label for="male">不可以转赠</label>
										<br>
										<input v-model="institution.share_mode" value="1" type="radio" name="s" id="sm1" class="with-gap">
										<label for="female" class="m-l-20">可以转赠</label>
									</div>
								</div> -->
								
							</div>


						</div>
					</div>
				</div>
				<!-- //// -->
				
				<div class="mt-3" v-else></div>

			</div>
		</div>

		
		
		<div class="form-group">
			<button v-if="institution.id>0" @click="deleteInstitution" type="button" class="btn btn-dark"> 删除 </button>
			<button @click="save" :disabled="disabled" type="button" class="btn btn-primary ml-2"> 保存 </button>
		</div>

		<!-- //main content -->


		<!-- modal -->
		<!-- 商户类型(到店) -->
		<div class="modal fade" id="mccModal" tabindex="-1" aria-labelledby="mccModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="mccModalLabel">指定商户类型</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div role="alert" class="alert alert-danger">
							<strong>注意事项</strong> 需要指定类目，请取消选中「不限」.
						</div>
						<table class="table">
						  <tbody>
						    <tr v-for="(item,index) in mcc_list" v-bind:key="index">
								<td class="align-top" style="width: 16px;">
									<div v-if="item.show" @click="toggleSubMccList(index)">
										<i class="icon icon-chevron-down text-dark"></i>
									</div>
									<div v-if="!item.show" @click="toggleSubMccList(index)">
										<i class="icon icon-chevron-right text-dark"></i>
									</div>
									</td>
								<td>
									<!-- 一级类目 -->
									<div class="form-check">
										<input 
										@change="onChangeMcc(index)"
										v-model="item.checked" 
										:disabled = "item.disabled"
										class="form-check-input" type="checkbox" :id="'mcc_cb_'+index">
										<label class="form-check-label" :for="'mcc_cb_'+index">
										 {{item.name}}
										</label>
									</div>
									
									<!-- 二级类目 -->
									<div style="margin-left: 20px;" v-show="item.show">
										<template v-for="(subitem,subindex) in item.items">
										<div class="form-check" v-bind:key="subindex" v-if="index == 0">
											<input 
											v-model="subitem.checked" 
											:disabled = "true"
											class="form-check-input" type="checkbox"  :id="'sub_mcc_cb_'+subindex">
											<label class="form-check-label" :for="'sub_mcc_cb_'+subindex">
											 {{subitem.name}}
											</label>
										</div>
										<div class="form-check" v-bind:key="subindex" v-if="index>0">
											<input 
											@change="onChangeSubMcc(index, subindex)"
											v-model="subitem.checked" 
											:disabled = "!item.checked"
											class="form-check-input" type="checkbox"  :id="'sub_mcc_cb_'+subindex">
											<label class="form-check-label" :for="'sub_mcc_cb_'+subindex">
											 {{subitem.name}}
											</label>
										</div>
										</template>
									</div>
								</td>
						    </tr>
						  </tbody>
						</table>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveMcc" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 指定商户 -->
		<div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">指定商户</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<input v-model="query" type="text" placeholder="搜索门店名称/PID/蚂蚁门店ID..." class="form-control" />
								</div>
								<div class="col">
									<div class="float-right">
										<button @click="searchMerchant" type="button" class="btn btn-dark"> 查询
										</button>
									</div>
								</div>
							</div>
							<template v-if='merchant_list.length > 0'>
								<hr>
								<div class="card">
									<div class="card-body no-m p-3 grey lighten-2">
										<div class="form-check">
											<label class="form-check-label">
												<input v-model="check_all"  @click.stop="checkAll" class="form-check-input" type="checkbox"> 所有
											</label>
										</div>
									</div>
								</div>
							</template>
							
							<template v-for="(item, index) in merchant_list">
								<div class="card mt-1 mb-1 border-0" v-bind:key="'t_'+index">
									<div class="card-body no-m p-1 pl-3">
										<div class="row" v-if="index==0">
											<div class="col-md-1 pt-2">
												
											</div>
											
											<div class="col-md-5">
												<span class="font-weight-bold">商户</span>
											</div>
										
											<div class="col-md-5">
												<span class="font-weight-bold">门店</span>
											</div>
										
											<!-- <div class="col-md-3">
												{{item.state}}{{item.city}}
											</div>
											<div class="col-md-4">
												{{item.street}}
											</div> -->
										</div>
									</div>
								</div>		
								<div class="card mt-1 mb-1" v-bind:key="index">
									<div class="card-body no-m p-1 pl-3 bg-light">
										<div class="row">
											<div class="col-md-1 pt-2">
												<input v-model="checkedIds" :value="index" :id="index" type="checkbox"
													:disabled="item.enabled == 0">
											</div>
											
											<div class="col-md-5">
												{{item.company_name}}
												<br>
												{{item.pid}}
											</div>

											<div class="col-md-5">
												<span class="font-weight-bold">{{item.name}}</span>
												<br>
												{{item.shop_id}}
											</div>

											<!-- <div class="col-md-3">
												{{item.state}}{{item.city}}
											</div>
											<div class="col-md-4">
												{{item.street}}
											</div> -->
										</div>
									</div>
								</div>
							</template>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveMch" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 商户类型(外卖) -->
		<div class="modal fade" id="categoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">指定商户类型</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="form-row">

								<div class="col-md-12 mb-3">
									<select  class="form-control" v-model="take_away_category_index">
										<option v-bind:key="index" :value="index"
											v-for="(item,index) in take_away_category_list">{{item.name}}</option>
									</select>
								</div>

							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="saveTakeAwayCategory" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>



		<!-- //modal -->
	</div>
</template>

<script>
	// import VueUploadImgs from '../components/VueUploadImgs.vue'
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';
	import 'vue2-datepicker/locale/zh-cn';

	export default {
		data() {
			return {
				title: '创建商品',

				token: '',

				institution: {
					dept_id: '',
					category: '',
					expense_type: 'DEFAULT',
					expense_type_sub_category: '',
					share_mode: 0,
					remark: '',
					card_list: '',
				},

				disabled: false,

				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				
				scenetypelist: [
					{name: '通用(默认)', value: 'DEFAULT'}
				],
				expensetypelist: [
					{name: '选择费用类型', value: ''},
					{name: '通用(默认)', value: 'DEFAULT'},
					{name: '餐饮', value: 'MEAL'},
					// {name: '商城', value: 'MALL'},
					// {name: '公交地铁', value: 'METRO'},
					// {name: '用车', value: 'CAR'},
					// {name: '话费充值', value: 'PREPAYRECHARGE'},
					// {name: '酒店', value: 'HOTEL'},
					// {name: '火车票', value: 'TRAIN'},
					// {name: '机票', value: 'PLANE'},
				],
				subexpensetypelist: [
					{name: '选择制度类型', value: ''},
					{name: '通用', value: 'DEFAULT'},
					{name: '餐饮-到店', value: 'REACH_SHOP'},
					{name: '餐饮-外卖', value: 'TAKE_AWAY'},
					// {name: '线上商城', value: 'ONLINE_MALL'},
					// {name: '公交地铁', value: 'METRO'},
					// {name: '用车', value: 'CAR'},
					// {name: '话费充值', value: 'ONLINE_PREPAY_RECHARGE'},
					// {name: '酒店-到店住宿', value: 'REACH_SHOP_HOTEL'},
					// {name: '酒店-在线订酒店', value: 'ONLINE_HOTEL'},
					// {name: '火车票-火车票预订', value: 'ONLINE_TRAIN'},
					// {name: '机票-机票预订', value: 'ONLINE_PLANE'},
				],
				
				mch_scope: 0,//可用商户，0不限，1商户类型，2指定商户
				// mcc_list: [],//餐饮商户类型二级分类
				// //到店餐饮商户类型二级分类
				// sub_mcc_list: [
				// 	{name: '不限', value: '-1'},
				// ],
				//外卖 餐饮商户类型一级分类
				take_away_category_list: [
					{name: '全部餐饮美食', value: 'MEAL'},
					{name: '快餐便当', value: 'FAST_FOOD_LUNCH'},
					{name: '中式菜系', value: 'CHINESE_CUISINE'},
					{name: '小吃/烧烤', value: 'SNACKS_BBQ'},
					{name: '奶茶果汁', value: 'DESSERT_DRINKS'},
					{name: '全球美食', value: 'GLOBAL_CUISINE'},
					{name: '香锅火锅', value: 'HOT_POT'},
				],
				take_away_category: 'MEAL',//指定商户类型的代码，如全量餐饮美食为MEAL
				take_away_category_index: 0,
				take_away_category_obj: {},//用于显示指定的商户类型名称
				
				merchant_list: [],
				
				// current_date: '',
				is_longtime: 0,
				
				query: '',
				check_all: false,
				checkedIds: [],//指定商户选中的商户的index
				mchList: [],//指定商户选中的商户
				
				//本地分类
				// categorylist: [
				// 	{name: '选择分类', value: ''},
				// 	{name: '弹性福利', value: 'DEFAULT'},
				// 	{name: '餐食外卖', value: 'MEAL'},
				// 	{name: '交通出行', value: 'CAR'},
				// 	{name: '工会福利', value: 'COMMON'},//OTHER
				// ],
				categorylist: this.GLOBAL.categorylist,
				
				////
				mcc_list: [
					{name: '不限', value: '-1', show: true, checked: true, items: 
						[
							{name: '不限', value: '-1', checked: true},
						]
					},
					{name: '餐饮', value: 'A0001', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '火锅', value: 'B0199'},
							{name: '中式正餐', value: 'B0001'},
							{name: '西式正餐', value: 'B0002'},
							{name: '日韩/东南亚菜', value: 'B0003'},
							{name: '中式快餐', value: 'B0004'},
							{name: '西式快餐', value: 'B0005'},
							{name: '小吃/熟食', value: 'B0006'},
							{name: '校园团餐', value: 'B0007'},
							{name: '综合团餐', value: 'B0008'},
							{name: '饮品/甜品', value: 'B0009'},
							{name: '烘焙糕点', value: 'B0010'},
							{name: '酒吧/清吧', value: 'B0011'},
							{name: '咖啡馆/茶馆', value: 'B0012'},
							{name: '宴会提供商', value: 'B0200'},
							{name: '订餐服务平台', value: 'B0117'},
						]
					},
					{name: '零售批发', value: 'A0002', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '小型商店', value: 'B0013'},
							{name: '连锁便利店', value: 'B0014'},
							{name: '大型超市', value: 'B0015'},
							{name: '大卖场', value: 'B0016'},
							{name: '百货商场', value: 'B0017'},
							{name: '购物中心', value: 'B0018'},
							{name: '奥特莱斯', value: 'B0019'},
							{name: '商业街', value: 'B0020'},
							{name: '水果店', value: 'B0021'},
							{name: '食品零售', value: 'B0201'},
							{name: '蔬菜/肉蛋/水产', value: 'B0202'},
							{name: '茶叶', value: 'B0022'},
							{name: '酒类', value: 'B0023'},
							{name: '烟草/雪茄', value: 'B0024'},
							{name: '营养品/保健品', value: 'B0025'},
							{name: '服饰鞋包', value: 'B0026'},
							{name: '美妆个护', value: 'B0027'},
							{name: '黄金珠宝/钟表饰品', value: 'B0028'},
							{name: '眼镜店', value: 'B0029'},
							{name: '3C数码/家用电器', value: 'B0030'},
							{name: '办公用品', value: 'B0203'},
							{name: '图文音像/工美乐器', value: 'B0031'},
							{name: '母婴用品/儿童玩具', value: 'B0032'},
							{name: '宠物/宠物用品', value: 'B0033'},
							{name: '户外运动器材', value: 'B0034'},
							{name: '五金建材', value: 'B0035'},
							{name: '家居家纺', value: 'B0216'},
							{name: '鲜花/绿植', value: 'B0036'},
							{name: '文物经营/文物复制品', value: 'B0037'},
							{name: '计生用品', value: 'B0038'},
							{name: '烟花爆竹', value: 'B0039'},
							{name: '危险化工产品', value: 'B0040'},
							{name: '石油及石油产品', value: 'B0041'},
							{name: '工业产品', value: 'B0042'},
							{name: '其他专业零售店', value: 'B0043'},
							{name: '互联网综合电商平台', value: 'B0114'},
							{name: '互联网垂直电商平台', value: 'B0115'},
							{name: '社区团购服务平台', value: 'B0118'},
						],
					},
					{name: '商业生活服务', value: 'A0003', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '美发/美容/美甲服务', value: 'B0044'},
							{name: '洗浴/保健养生服务', value: 'B0045'},
							{name: '家政/清洁服务', value: 'B0046'},
							{name: '电器/家具/其他维修', value: 'B0047'},
							{name: '建筑装饰/装修服务', value: 'B0048'},
							{name: '地产/房屋中介代理', value: 'B0049'},
							{name: '宠物医院/其他宠物服务', value: 'B0050'},
							{name: '数码/娱乐设备租赁', value: 'B0051'},
							{name: '无人值守自助服务', value: 'B0052'},
							{name: '共享充电宝及其他共享租赁', value: 'B0212'},
							{name: '无人值守自助零售', value: 'B0213'},
							{name: '无人值守自助娱乐', value: 'B0214'},
							{name: '婚介/婚庆/摄影服务', value: 'B0053'},
							{name: '广告/会展/图文印刷', value: 'B0054'},
							{name: '法律咨询/律师事务所', value: 'B0055'},
							{name: '会计/金融咨询服务', value: 'B0056'},
							{name: '征信和信用报告咨询服务', value: 'B0057'},
							{name: '人才招聘服务', value: 'B0058'},
							{name: '丧葬服务', value: 'B0059'},
							{name: '拍卖/收藏', value: 'B0060'},
							{name: '其他商业及生活服务', value: 'B0061'},
							{name: '默认行业', value: 'B0223'},
							{name: '生活服务平台', value: 'B0116'},
							{name: '在线工具', value: 'B0112'},
							{name: '物业管理', value: 'B0165'},
						],
					},
					{name: '休闲娱乐', value: 'A0004', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '歌舞厅/休闲会所/KTV', value: 'B0062'},
							{name: '棋牌桌游/电玩网吧', value: 'B0063'},
							{name: '健身房/瑜伽/舞蹈', value: 'B0064'},
							{name: '院线影城/演出赛事', value: 'B0065'},
							{name: '游乐园/嘉年华', value: 'B0066'},
							{name: '文化场馆', value: 'B0067'},
							{name: '体育场馆', value: 'B0068'},
							{name: '彩票', value: 'B0069'},
							{name: '其他文体娱乐', value: 'B0070'},
							{name: '网络社交', value: 'B0108'},
							{name: '网络图书/视频/音乐', value: 'B0109'},
							{name: '游戏', value: 'B0110'},
							{name: '游戏周边服务/交易平台', value: 'B0205'},
							{name: '网络直播', value: 'B0111'},
						],
					},
					{name: '车主生活', value: 'A0005', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '车辆零配件/用品精品', value: 'B0071'},
							{name: '汽车洗美/维修养护', value: 'B0072'},
							{name: '车辆销售', value: 'B0073'},
							{name: '二手车销售', value: 'B0074'},
							{name: '停车服务', value: 'B0075'},
							{name: '代驾服务', value: 'B0076'},
							{name: '电动汽车充换电', value: 'B0077'},
							{name: '两轮电瓶车充换电', value: 'B0078'},
							{name: '汽车租赁', value: 'B0079'},
							{name: 'ETC不停车自动缴费', value: 'B0080'},
							{name: 'MTC半自动车道收费', value: 'B0081'},
							{name: '高速服务区', value: 'B0082'},
							{name: '道路救援', value: 'B0083'},
						],
					},
					{name: '交通出行', value: 'A0006', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '公路客运', value: 'B0084'},
							{name: '出租车客运', value: 'B0085'},
							{name: '网约车客运', value: 'B0086'},
							{name: '地铁', value: 'B0087'},
							{name: '公共交通', value: 'B0088'},
							{name: '共享两轮车服务', value: 'B0089'},
							{name: '加油卡、加油服务', value: 'B0090'},
							{name: '加油站、加气站', value: 'B0091'},
							{name: '航空公司', value: 'B0097'},
							{name: '机场', value: 'B0098'},
							{name: '机票代理人', value: 'B0099'},
							{name: '航空系统商', value: 'B0100'},
							{name: '铁路旅客运输', value: 'B0101'},
							{name: '游轮及巡游航线服务', value: 'B0102'},
						],
					},
					{name: '物流运输', value: 'A0015', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '物流/快递', value: 'B0092'},
							{name: '快递服务（个人商户）', value: 'B0093'},
							{name: '物流平台', value: 'B0217'},
							{name: '同城即时配送', value: 'B0218'},
							{name: '邮政基本服务', value: 'B0094'},
							{name: '驿站/自提柜', value: 'B0095'},
							{name: '仓储', value: 'B0096'},
							{name: '铁路货物运输', value: 'B0103'},
							{name: '船舶、海运服务提供商', value: 'B0104'},
							{name: '货品停放交易（国际专用）', value: 'B0105'},
							{name: '国际货运代理和报关行', value: 'B0106'},
							{name: '其他运输代理业', value: 'B0107'},
						],
					},
					{name: '网络通讯', value: 'A0007', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '信息检索服务/网络论坛', value: 'B0113'},
							{name: '互联网数据服务', value: 'B0120'},
							{name: '软件开发服务', value: 'B0121'},
							{name: '数字类产品-游戏（国际专用）', value: 'B0122'},
							{name: '数字类产品-软件提供商（国际专用）', value: 'B0123'},
							{name: '数字类产品-其他（国际专用）', value: 'B0124'},
							{name: '其他在线应用或综合类服务', value: 'B0125'},
							{name: '网络推广/网络广告', value: 'B0126'},
							{name: '电信运营商', value: 'B0127'},
							{name: '付费电视', value: 'B0128'},
							{name: '网络电话、传真服务', value: 'B0129'},
						],
					},
					{name: '教育培训', value: 'A0008', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '儿童保育服务（公立，含学前教育）', value: 'B0130'},
							{name: '儿童保育服务（民办，含学前教育）', value: 'B0131'},
							{name: '中小学校（公立）', value: 'B0132'},
							{name: '中小学校（民办）', value: 'B0133'},
							{name: '大学与学院（公立）', value: 'B0134'},
							{name: '大学与学院（民办）', value: 'B0135'},
							{name: '其他学校/公立培训机构', value: 'B0136'},
							{name: '教育管理机构', value: 'B0215'},
							{name: '教育培训机构', value: 'B0137'},
							{name: '少年宫及青少年发展中心', value: 'B0138'},
							{name: '其他教育/培训', value: 'B0139'},
						],
					},
					{name: '医疗卫生', value: 'A0009', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '医药销售', value: 'B0140'},
							{name: '医疗器械销售', value: 'B0141'},
							{name: '保健辅助治疗器材', value: 'B0142'},
							{name: '公立医院', value: 'B0143'},
							{name: '社区卫生服务中心', value: 'B0144'},
							{name: '专业公共卫生机构', value: 'B0145'},
							{name: '民营医院', value: 'B0146'},
							{name: '诊所', value: 'B0147'},
							{name: '个体医生', value: 'B0148'},
							{name: '医疗美容', value: 'B0149'},
							{name: '眼科医疗服务', value: 'B0150'},
							{name: '口腔医疗服务', value: 'B0151'},
							{name: '医学实验室及诊断中心', value: 'B0152'},
							{name: '线上医疗服务', value: 'B0153'},
							{name: '护理机构服务', value: 'B0154'},
							{name: '体检/健康咨询', value: 'B0155'},
							{name: '其他医疗保健服务', value: 'B0156'},
						],
					},
					{name: '酒旅景区', value: 'A0010', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '酒店/旅馆/民宿', value: 'B0157'},
							{name: '景区', value: 'B0158'},
							{name: '旅行社和旅游服务', value: 'B0159'},
							{name: '旅游相关服务直销', value: 'B0160'},
							{name: '风景区系统商', value: 'B0219'},
							{name: '观光车', value: 'B0220'},
							{name: '游船码头', value: 'B0221'},
							{name: '旅游服务平台（OTA）', value: 'B0119'},
						],
					},
					{name: '生活缴费', value: 'A0011', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '公共事业（电、气、水）', value: 'B0161'},
							{name: '公共事业-电力缴费', value: 'B0204'},
							{name: '公共事业-煤气缴费', value: 'B0211'},
							{name: '公共事业-自来水缴费', value: 'B0206'},
							{name: '公共事业-有线电视缴费', value: 'B0162'},
							{name: '公共事业-清洁服务缴费', value: 'B0207'},
							{name: '公共事业-其他缴费', value: 'B0163'},
							{name: '话费充值与缴费', value: 'B0164'},
						],
					},
					{name: '金融理财', value: 'A0012', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '金融机构', value: 'B0166'},
							{name: '金融机构-其他服务', value: 'B0167'},
							{name: '外币汇兑', value: 'B0168'},
							{name: '股票基金服务', value: 'B0169'},
							{name: '小贷公司', value: 'B0170'},
							{name: '借贷消费平台', value: 'B0171'},
							{name: '融资租赁公司', value: 'B0172'},
							{name: '金融租赁公司', value: 'B0173'},
							{name: '汽车金融公司', value: 'B0174'},
							{name: '消费金融公司', value: 'B0175'},
							{name: '信托公司', value: 'B0176'},
							{name: '支付机构', value: 'B0177'},
							{name: '融资担保公司', value: 'B0178'},
							{name: '其他金融服务', value: 'B0179'},
							{name: '保险业务/保险代理', value: 'B0180'},
							{name: '典当', value: 'B0181'},
						],
					},
					{name: '公共管理和社会组织', value: 'A0013', show: false, checked: false, disabled: true,   items: 
						[
							{name: '不限', value: '-1'},
							{name: '政府机构', value: 'B0182'},
							{name: '行政费用和罚款（非税）', value: 'B0183'},
							{name: '公积金', value: 'B0184'},
							{name: '公安交管', value: 'B0185'},
							{name: '医保', value: 'B0186'},
							{name: '社会保障服务', value: 'B0187'},
							{name: '政府采购', value: 'B0188'},
							{name: '文体机构', value: 'B0222'},
							{name: '政府贷款', value: 'B0189'},
							{name: '法庭费用', value: 'B0190'},
							{name: '保释金', value: 'B0191'},
							{name: '税务', value: 'B0192'},
							{name: '慈善和社会公益服务', value: 'B0193'},
							{name: '使领馆', value: 'B0194'},
							{name: '行业协会和专业社团', value: 'B0195'},
							{name: '汽车协会', value: 'B0196'},
							{name: '宗教组织', value: 'B0197'},
						],
					},
				],
				mcc:{"-1": ["-1"]},//默认MCC
			}
		},

		components: {
			// VueUploadImgs
			DatePicker
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			//2.取得店铺信息
			// let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			// console.log(lsLoginData.logined_outlet_name)
			// // self.outlet_id = lsLoginData.logined_outlet_id;
			// console.info('--- this.$store.state ->', this.$store.state)
			// self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			// self.outlet_name = lsLoginData.logined_outlet_name;
			// self.user_name = lsLoginData.user_account;

			// self.setting = JSON.parse(localStorage.getItem('SETTING'));

			// self.merchant_list = JSON.parse(localStorage.getItem('OUTLETLIST'));
			// console.log('--- outlet list ->', self.merchant_list)

			// //指定店铺
			// // let selectedOutletId = getQueryString("outlet_id");
			// console.log('--- this.$route>', this.$route)
			// let selectedOutletId = this.$route.params.outlet_id;
			// if (selectedOutletId) {
			// 	self.outlet_id = selectedOutletId;
			// }

			// //是否编辑
			// let selectedProductBarcode = this.$route.query.no;
			// if (selectedProductBarcode) {
			// 	var _params = this.$route.query;
			// 	// self.selectedOutletId = _params.outlet_id;
			// 	self.resetProduct(_params);
			// }
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }

			self.init();
		},
		methods: {
			init(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							// dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;
							
							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);
							
							self.institution.effective_start_date = data.data.current_date;
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},
			
			initData() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getCyCategoryList', {
						params: {
							token: self.token,
							outlet_id: self.outlet_id,
							// name: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.categorylist = data.data;

							if (self.categorylist.length > 0) {
								if (!self.institution.id) { //新增时默认第一个
									self.institution.category_id = self.categorylist[0].id || 0;
								}
							} else {
								// alert('该店铺没有商品分类');
								self.$toasted.error('该店铺没有商品分类', {
									position: 'top-center',
								}).goAway(2000)
							}

							// __LIST = data.data;
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init();
			},


			onstatechange(state) {
				console.log('--- state  -> ', state)
			},
			
			back(){
				let self = this;
				self.$router.push({
					path: 'institutions',
					params: {
						outlet_id: self.outlet_id
					}
				})
			},

			save() {
				let self = this;
				console.log(self.institution);
				// return;


				//0.构建institution对象数据
				self.buildInstitution();
				console.log('--- institution after build->', self.institution)

				//1.校验
				if (self.institution.category == '') {
					self.$toasted.error('请选择分类', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (self.institution.dept_id == '') {
					self.$toasted.error('请选择归属企业', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.expense_type_sub_category) {
					self.$toasted.error('请选择制度类型', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.institution_name) {
					self.$toasted.error('制度名称不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (!self.institution.quota_total) {
					self.$toasted.error('金额不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				if (self.institution.quota_total <= 0) {
					self.$toasted.error('金额必须大于0', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				//公交地铁有
				if (self.institution.expense_type_sub_category == 'METRO' && !self.institution.card_list) {
					self.$toasted.error('卡类型不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.institution.effective_start_date) {
					self.$toasted.error('有效期开始日期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				if (!self.institution.effective_end_date) {
					self.$toasted.error('有效期结束日期不能为空', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.saveInstitution();
			},

			//保存提交前，构建institution：处理standard使用规则：规则因子等
			buildInstitution() {
				let self = this;
				
				//1.可用商户
				//a.餐饮-到店：需要处理 meal_merchant
				if(self.institution.expense_type_sub_category == 'REACH_SHOP'){
					if(self.mch_scope == 1){//指定商户
						//meal_merchant结构：
						// {
						//   "pid1":["shopid1","shopid2"],
						//   "pid2":["shopid3","shopid4"]
						// }
						//>> 结构更改为
						// [
						// 	{pid: "pid1":,shop_id: "shopid1"},
						// 	{pid: "pid2":,shop_id: "shopid2"},
						// ]
						
						let mmArr = [];
						for(let i=0; i<self.mchList.length; i++){
							let pid = self.mchList[i].pid;
							let shop_id = self.mchList[i].shop_id;
							let mmObj = {
								pid: pid,
								shop_id: shop_id,
							}
							mmArr.push(mmObj);
						}
						
						self.institution.meal_merchant = JSON.stringify(mmArr);
					}
					else if(self.mch_scope == 0){//其它：不限或商户类型（到店的商户类型也是不限）
						self.institution.meal_merchant = '';//设为空，表示不限商户
					}
					else if(self.mch_scope == 3){//202304:指定商户类型
						self.institution.mcc = JSON.stringify(self.mcc);
					}
				}
				
				//b.餐饮-外卖：需要处理 elm_merchant
				if(self.institution.expense_type_sub_category == 'TAKE_AWAY'){
					if(self.mch_scope == 0){//不限：相当于指定商户类型：全量餐饮美食
						self.institution.take_away_category = 'MEAL';
					}
					else if(self.mch_scope == 2){//指定商户类型
						self.institution.take_away_category = self.take_away_category;
					}
					else if(self.mch_scope == 1){//指定商户
						//elm_merchant结构：
						//["shopId1", "shopId2"]
						let mmArr = [];
						for(let i=0; i<self.mchList.length; i++){
							// let pid = self.mchList[i].pid;
							let shop_id = self.mchList[i].shop_id;
							// let mmObj = {
							// 	pid: pid,
							// 	shop_id: shop_id,
							// }
							mmArr.push(shop_id);
						}
						
						self.institution.elm_merchant = JSON.stringify(mmArr);
					}
				}
				
				//c.通用：需要处理 merchant
				if(self.institution.expense_type_sub_category == 'DEFAULT'){
					if(self.mch_scope == 1){//指定商户
						//MERCHANT结构：
						// {
						//   "pid1":["shopid1","shopid2"],
						//   "pid2":["shopid3","shopid4"]
						// }
						//>> 结构更改为
						// [
						// 	{pid: "pid1":,shop_id: "shopid1"},
						// 	{pid: "pid2":,shop_id: "shopid2"},
						// ]
						
						let mmArr = [];
						for(let i=0; i<self.mchList.length; i++){
							let pid = self.mchList[i].pid;
							let shop_id = self.mchList[i].shop_id;
							let mmObj = {
								pid: pid,
								shop_id: shop_id,
							}
							mmArr.push(mmObj);
						}
						
						self.institution.merchant = JSON.stringify(mmArr);
					}
					else{//其它：不限或商户类型（通用的商户类型也是不限）
						self.institution.merchant = '';//设为空，表示不限商户
					}
				}

				//TODO：随机数，预留
				let nonce_str = new Date().getTime() + "" + parseInt(Math.random() * 10000);
				self.institution.nonce_str = nonce_str;
			},

			//保存制度
			saveInstitution() {
				let self = this;

				self.disabled = true;

				// 组装数据
				let postData = self.institution;

				postData['token'] = self.token;
				postData["mch_scope"] = self.mch_scope;

				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}



				//调用接口
				this.axios.post(this.GLOBAL.baseURI + 'addInstitution',
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error('创建失败', {
							position: 'top-center',
						}).goAway(2000)

						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						self.disabled = false;

						if (data.code == 200) {
							if (data.data.result_code == 'success') {
								self.$toasted.success('创建成功', {
									position: 'top-center',
								}).goAway(2000)

								self.$router.push({
									path: 'institutions',
									params: {
										outlet_id: self.outlet_id
									}
								})
							}
							else{
								self.$toasted.error( data.data.result_msg ,{
									position: 'top-center',
								}).goAway(2000)
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)

							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				//END
			},

			//重置商品
			resetInstitution(params) {
				console.log('--- 编辑商品，params ->', params)
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getCyProductsByBarcode', {
						params: {
							token: self.token,
							// outlet_id: params.outlet_id,
							outlet_id: self.outlet_id,
							barcode: params.no,
							nonce_str: params.nonce_str,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						console.log('--- getCyProductsByBarcode --- ,res->', data);

						if (data.code == 200) {
							var _specs = [],
								_tastes = [],
								_attachs = [];

							var institutions = data.data.institutions;
							if (institutions.length == 0) return;

							var _institution = institutions[0];

							self.institution = Object.assign({}, _institution); //重置institution
							self.institution["institution_id"] = _institution.id;
							self.institution["img_url"] = "";

							//菜品处理 start
							if (_institution.institution_type == 1) {

								// 多规格处理，组装specList
								if (institutions.length > 1) {
									for (var p of institutions) {
										var _spec = {};
										_spec["id"] = p.id;
										_spec["unit"] = p.unit;
										_spec["item_no"] = p.item_no;
										_spec["price"] = p.price;
										_spec["vip_price"] = p.vip_price;
										_spec["supply_price"] = p.supply_price;
										_specs.push(_spec);
									}
								}

								console.log("--- spes list->", _specs);

							} else {
								//关闭菜品特有的项
							}
							//菜品处理 end

							//商品图片处理
							// self.institutionImg, = _institution.img_url == 'HAVE' ? baseImgURI + _institution.id +
							// 	'.jpg?x-oss-process=style/sm-150x150&' + Math.random() : '',

							if (_institution.img_url == 'HAVE') {
								self.files.push({
									url: self.GLOBAL.baseImgURI + _institution.id +
										'.jpg?x-oss-process=style/sm-150x150&' + Math.random(),
									name: _institution.id + '.jpg'
								})
							}

							//规格、偏好、加料
							self.specList = _specs;
							self.tasteList = _institution.tastes;
							self.attachList = _institution.attachs;

							self.old_institution_name = _institution.institution_name;
							self.old_barcode = _institution.barcode;
						} else if (data.code == 404) {
							// Vue.toasted.error( '未找到商品', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$router.push({
								name: '404'
							});

							return;
						} else {
							// alert(data.message);

							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })

							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END

			},

			deleteInstitution() {
				let self = this;

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'deleteCyProduct', {
						params: {
							token: self.token,
							outlet_id: self.institution.outlet_id,
							institution_id: self.institution.institution_id,
							del_same_barcode: 1, //是否删除同码多规格的所有商品
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);

						if (data.code == 200) {
							// alert('删除成功');

							// Vue.toasted.success( '删除成功', {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.success('删除成功', {
								position: 'top-center',
							}).goAway(2000)

							self.$router.push({
								path: 'institutions',
								params: {
									outlet_id: self.outlet_id
								}
							})
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message);
							// Vue.toasted.error( data.message, {
							// 	position: 'top-center',
							// 	duration: '2000',
							// })
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}

					});
				//END
			},

			
			onChangeMchScope(ms){
				console.log('--- onChangeMchScope, ms ->',ms)
				let self = this;
				
				if(ms==0){
					//重置mchList和take_away_category
					self.mchList =[];
					self.take_away_category = 'MEAL';
				}
				else if(ms==1){
					if(self.institution.dept_id==''){
						self.$toasted.error('请选择归属企业', {
							position: 'top-center',
						}).goAway(2000)
						
						return;
					}
					
					self.query = '';
					self.merchant_list = [];
					self.checkedIds = [];
					
					$('#searchModal').modal('show');
				}
				else if(ms==2){
					// self.take_away_category_index = 0;
					// self.take_away_category_obj = {};
					
					$('#categoryModal').modal('show');
				}
				else if(ms==3){//202403: 餐饮到店MCC
					$('#mccModal').modal('show');
				}
			},
			
			onChangeSubExpenseType(){
				let self = this;
				
				self.mch_scope = 0;//费用子类型变更，重置可用商户
			},
			
			saveTakeAwayCategory(){
				let self = this;
				
				self.take_away_category = self.take_away_category_list[self.take_away_category_index].value;
				self.take_away_category_obj = Object.assign({}, self.take_away_category_list[self.take_away_category_index]);
				
				$('#categoryModal').modal('hide');
			},
			
			//202403:MCC 
			toggleSubMccList(index){
				let self = this;
				self.mcc_list[index].show = !self.mcc_list[index].show;
			},
			
			saveMcc(){
				let self = this;
				
				//设置一级类目与二级类目
				self.mcc = {};//初始化
				
				if(self.mcc_list[0].items[0].checked){//一级不限时
					self.mcc = {"-1": ["-1"]}//重置默认MCC
				}
				else{
					for(let i=1; i<self.mcc_list.length; i++){
						if(self.mcc_list[i].checked){//一级选中
							//二级处理：加入选中的
							let sub_mcc = [];
							for(let j=0; j<self.mcc_list[i].items.length; j++){
								if(self.mcc_list[i].items[j].checked){
									sub_mcc.push(self.mcc_list[i].items[j].value);
								}
							}
							self.mcc[self.mcc_list[i].value] = sub_mcc;
						}
					}
				}
				
				console.error('---- 保存 self.mcc ->', JSON.stringify(self.mcc));
				
				if(JSON.stringify(self.mcc) ==='{}'){
					self.$toasted.error('请指定商户类型.', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}
				
				$('#mccModal').modal('hide');
			},
			
			onChangeMcc(index){
				console.log('--- index @ onChangeMcc ->',index)
				let self = this;
				
				//当选中一级时，必须展开其下的二级，且默认二级选中不限
				//当取消选中一级时，必须取消期有下的二级选中状态
				if(self.mcc_list[index].checked){//一级选中
					self.mcc_list[index].show = true;//展开二级
					self.mcc_list[index].items[0].checked = true;//二级「不限」选中
				}
				else{//一级取消选中时
					// self.mcc_list[index].show = false;//可以不收起来，以便看清二级选中的
					for(let i=0; i<self.mcc_list[index].items.length; i++){
						self.mcc_list[index].items[i].checked = false;
					}
				}
				
				// 当一级不限选中，其它一级disabled，且取消选中以及其下二级也取消
				// 当一级不限取消选中，其它一级解除disabled
				if(index == 0){
					if(self.mcc_list[index].checked){
						for(let i=1; i<self.mcc_list.length; i++){
							self.mcc_list[i].checked = false;
							self.mcc_list[i].disabled = true;
							
							//二级处理：全取消选中，且disabled
							for(let j=0; j<self.mcc_list[i].items.length; j++){
								self.mcc_list[i].items[j].checked = false;
								self.mcc_list[i].items[j].disabled = true;
							}
						}
					}
					else{
						for(let i=1; i<self.mcc_list.length; i++){
							self.mcc_list[i].disabled = false;
						}
					}
				}
			},
			
			onChangeSubMcc(index, subindex){
				console.log('---  index, subindex @ onChangeSubMcc ->',index, subindex)
				let self = this;
				
				let current_submcc = self.mcc_list[index].items[subindex];//当前二级类目
				console.log('---  current_submcc ->',current_submcc)
				
				//I.选中
				//当选中二级「不限」项时，其它项要全取消
				//当选中二级其它项时，「不限」要取消掉
				if(current_submcc.checked){
					if(subindex == 0){//0表示选中不限
						//从第2个开始，全部设为false
						for(let i=1; i<self.mcc_list[index].items.length; i++){
							//self.mcc_list[index].items[i].checked = false;////vue UI刷新问题，需要用$set
							let submcc = self.mcc_list[index].items[i];
							submcc.checked = false;
							self.$set(self.mcc_list[index].items, i, submcc);
						}
					}
					else{
						// self.mcc_list[index].items[subindex].checked = true;//自己设为true？？？本来可以v-model自动设置
						current_submcc.checked = true;
						self.$set(self.mcc_list[index].items, subindex, current_submcc)
						self.mcc_list[index].items[0].checked = false;//不限设为false
					}
				}
				
				console.log('---- self.mcc_list[index].items[subindex] ->',self.mcc_list[index].items[subindex])
			},
			
			onChangeEndDate(){
				let self = this;
				
				if(self.is_longtime==1){
					self.institution.effective_end_date = '2099-12-31'//生效结束日期为长期时，设为2099年
				}
				else{
					self.institution.effective_end_date = '';
				}
			},
			
			searchMerchant(){
				let self = this;
				
				self.current_page = 1;
				self.page_size = 50;
				self.initMerchantData();
			},
			
			initMerchantData() {
				let self = this;
			
				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getMerchantList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							// start_date: self.start_date,//有效期
							// end_date: self.end_date,
							query: self.query,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
			
							self.merchant_list = data.data.merchant_list;
							
							// self.start_date = data.data.start_date;
							// self.end_date = data.data.end_date;
			
							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)
			
							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;
			
							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数
			
							// self.merchantlist = []; //重置
							// for (let merchant of data.data.list) {
							// 	// merchant.typeDesc = __STOCKTYPE[merchant.stock_type];
							// 	// merchant.stateDesc = merchant.stock_state?__STOCKSTATE[merchant.stock_state]:""
								
							// 	self.merchantlist.push(merchant);
							// }
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			
			},
			
			checkAll() {
				let self = this;
				self.check_all = !self.check_all;
				console.log('--- check all ---', self.check_all)
			
				if (self.check_all) {
					for (var i = 0; i < self.merchant_list.length; i++) {
						if (self.merchant_list[i].enabled == 0) continue;
						// self.checkedIds.push(self.merchant_list[i].id);
						self.checkedIds.push(i);
					}
				} else {
					self.checkedIds = [];
				}
			},
			
			//暂存选择的指定商户
			saveMch() {
				let self = this;
				console.log('--- checkedIds ---', self.checkedIds)
				
				if(self.checkedIds.length==0){
					self.$toasted.error( '请选择商户', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.mchList = [];
				for(let i=0; i<self.checkedIds.length; i++){
					let index = self.checkedIds[i];
					self.mchList.push(self.merchant_list[index]);
				}
				
				console.log('--- mchList @ saveMch>', self.mchList);
				
				$('#searchModal').modal('hide');
			},

		},
	}
</script>

<style scoped>

</style>
